<template>
  <div class="h-100">
    <div
      v-if="folders.length > 0"
      class="d-flex align-items-center mt-n1 mb-50"
    >
      <a class="mr-50" @click="goBack()">
        <feather-icon icon="ChevronLeftIcon" size="20" />
      </a>
      <p class="mb-0">
        {{ folders[folders.length - 1].name }}
      </p>
    </div>
    <b-table
      v-if="items && items.length > 0 && canList"
      hover
      :busy="isLoading || isLoadingNextPage"
      :items="items"
      :fields="isWidget ? fieldsWidget : fieldsList"
      responsive
      :sticky-header="isWidget ? true : '58vh'"
      foot
    >
      <!-- Column: Name -->
      <template #cell(name)="{ item }">
        <b-media class="d-flex align-items-center ml-25 mt-25">
          <template>
            <div
              v-if="item.mediaType === 'folder'"
              class="text-dark d-flex align-items-center"
              @click="openFolder(item)"
            >
              <div>
                <div
                  class="d-flex align-items-center justify-content-center overflow-x-hidden"
                  style="width: 58px; height: 25px"
                >
                  <b-img
                    src="../../../../assets/images/icons/folder-icon.svg"
                    height="45"
                  />
                </div>
              </div>
              <div>
                <p v-if="item.name" class="font-weight-extrabold mb-0">
                  {{ translate(item.name) }}
                </p>
                <span v-else class="text-muted">---</span>
              </div>
            </div>
            <b-link
              v-else
              class="text-dark d-flex align-items-center"
              :to="{
                name: `storage-details`,
                params: {
                  itemKey: item.key,
                  communityId: $route.params.communityId,
                },
              }"
            >
              <div>
                <div
                  class="mr-2 w-100 h-100 d-flex align-items-center justify-content-center overflow-x-hidden"
                >
                  <safe-img
                    class="thumbnail"
                    :src="getImageSrc(item.thumbnailURL)"
                    :placeholder="placeholderItems(item.expectedTypo)"
                    :alt="title(item.name)"
                    :retry="5000"
                    height="25"
                    width="37"
                  />
                </div>
              </div>
              <div>
                <p v-if="item.name" class="font-weight-extrabold mb-0">
                  {{ title(item.name) }}
                </p>
                <span v-else class="text-muted">---</span>
              </div>
            </b-link>
          </template>
        </b-media>
      </template>
      <!-- Column: kind -->
      <template #cell(expectedTypo)="{ item }">
        <div class="w-100 h-100 d-flex pl-1 ml-50 align-items-center" style="max-width: 120px">
          <p class="mb-0">
            {{
              item.mediaType === "folder"
                ? "Folder"
                : `${
                  item.isLink
                    ? "WWW"
                    : getFileExtensión(item.url).toUpperCase()
                } - ${item.expectedTypo}`
            }}
          </p>
        </div>
      </template>
      <!-- Column: size -->
      <template #cell(size)="{ item }">
        <div class="w-100 h-100 d-flex align-items-center justify-content-end" style="max-width: 120px">
          <p class="mb-0">
            {{
              item.mediaType === "folder" || item.isLink
                ? "--"
                : item.size
                  ? item.size + " MB"
                  : "--"
            }}
          </p>
        </div>
      </template>
      <!-- Column: Date -->
      <template #cell(createdAt)="{ item }">
        <div class="w-100 h-100 d-flex pl-1 ml-50 align-items-center" style="max-width: 120px">
          <p class="mb-0">
            {{ timestampToTime(item.createdAt) }}
          </p>
        </div>
      </template>
      <!-- Column: likes -->
      <template #cell(totalLikes)="{ item }">
        <div class="w-100 h-100 d-flex align-items-center justify-content-end" style="max-width: 120px">
          <p class="mb-0">
            {{ item.totalLikes }}
          </p>
        </div>
      </template>
      <!-- Column: comments -->
      <template #cell(commentCount)="{ item }">
        <div class="w-100 h-100 d-flex align-items-center justify-content-end" style="max-width: 120px">
          <p class="mb-0">
            {{ item.commentCount }}
          </p>
        </div>
      </template>
      <!-- Column: Descargable -->
      <template #cell(actions)="{ item }">
        <small class="float-right ml-1">
          <b-button
            variant="link"
            class="p-0 m-0"
            target="_blank"
            :disabled="!canDelete"
            @click="showUnshare(item)"
          >
            <feather-icon icon="Trash2Icon" size="16" />
          </b-button>
        </small>
        <small class="float-right ml-1">
          <b-button
            variant="link"
            class="p-0 m-0"
            target="_blank"
            :disabled="!canEdit"
            @click="showEdit(item)"
          >
            <feather-icon icon="Edit2Icon" size="16" />
          </b-button>
        </small>
        <small v-if="item.mediaType !== 'folder'" class="float-right ml-1">
          <b-button
            variant="link"
            class="p-0 m-0"
            :href="item.downloadMediaURL"
            target="_blank"
            :disabled="!item.canDownload"
          >
            <feather-icon icon="DownloadIcon" size="16" />
          </b-button>
        </small>
      </template>
      <template v-if="items.length < total" #table-caption>
        <b-button
          v-if="!isLoadingNextPage"
          class="center-x"
          variant="outline-primary"
          @click="handleLoadOfNewItems"
        >
          {{ $t("action.load-more") }}
        </b-button>
        <div v-else class="w-100">
          <b-spinner
            class="center-x"
            small
            variant="primary"
            type="grow"
            label="Loading..."
          />
        </div>
      </template>
    </b-table>
    <div v-else-if="isLoading">
      <b-spinner type="grow" small class="mx-auto mt-3 d-block" />
    </div>
    <b-row v-else-if="!canList" class="horizontal-placeholder">
      <b-col cols="12">
        <img :src="storagePlaceholder">
      </b-col>
      <b-col cols="12">
        <p class="text-primary">
          {{ $t("no-permisions.message") }}
        </p>
      </b-col>
    </b-row>

    <!-- Container without data: Placeholder -->
    <b-row v-else class="horizontal-placeholder">
      <b-col v-if="storagePlaceholder" cols="12">
        <img :src="storagePlaceholder">
      </b-col>
      <b-col cols="12">
        <p class="text-primary">
          {{ $t("available.message", { itemName: "folders" }) }}
        </p>
      </b-col>
    </b-row>
    <unshare-item-modal
      v-model="isUnshareModalVisible"
      :model-type="tempItem.isFolder ? 'folder' : 'file'"
      @remove-item="deleteItem(tempItem.key, tempItem.isFolder)"
      @modal-invisible="isUnshareModalVisible = false"
    />
    <b-modal
      id="modal-edit-storage"
      :title="$t('media.form-edit-item.title')"
      centered
      size="lg"
      hide-footer
    >
      <template>
        <language-selector-header
          v-if="storageInput.isFile"
          title=""
          @selectLanguage="(language) => (selectedLanguage = language)"
        />
        <b-form @submit.prevent="handleEdit()">
          <b-form-group>
            <label for="title">{{ $t("media.form-edit-item.title") }}:</label>
            <b-form-input
              id="title"
              v-model="storageInput.title[selectedLanguage]"
              type="text"
              required
              rules="required"
              name=" "
              :placeholder="$t('media.form-edit-item.title')"
            />
          </b-form-group>
          <div class="float-right mb-2">
            <!-- Missing to implement BE -->
            <b-button variant="primary" class="ml-2" type="submit">
              {{ $t("form.actions.save") }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import moment from 'moment';
import StoragePlaceholder from '@/assets/images/placeholders/light/storage.svg';
import { checkPermissions } from '@/@core/utils/roles-utils';
import { getImageResource } from '@/@core/utils/image-utils';
import UnshareItemModal from '@/@core/components/modal/UnshareItemModal.vue';
import { PlaceholdersImage } from '@/views/apps/media/constants/MediaType';
import SafeImg from '@core/components/safe-img/SafeImg.vue';
import LanguageSelectorHeader from '@core/components/modal/LanguageSelectorHeader.vue';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';

export default {
  name: 'StorageTable',
  
  components: {
    SafeImg,
    UnshareItemModal,
    LanguageSelectorHeader,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    isWidget: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      searchInput: '',
      results: [],
      storageInput: {
        title: '',
      },
      // Filters data
      areFiltersVisible: false,
      nextFilter: null,
      isUnshareModalVisible: false,
      selectedClassifiersForFilter: [],
      finalSelectClassifier: [],
      selectedCustomFieldsForFilter: null,
      customFieldToSend: {},
      showfilter: false,
      folderKey: null,
      folders: [],
      tempItem: {},
      isLoadingNextPage: false,
      isLoading: false,
      selectedLanguage: this.currentLocale,
    };
  },
  computed: {
    canDelete() {
      return checkPermissions(
        'delete',
        'storage',
        this.loggedMemberRoles,
        this.community,
      );
    },
    canEdit() {
      return checkPermissions(
        'edit',
        'storage',
        this.loggedMemberRoles,
        this.community,
      );
    },
    fieldsList() {
      return [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'expectedTypo',
          label: 'Kind',
          sortable: true,
        },
        {
          key: 'size',
          label: 'size',
          sortable: true,
        },
        {
          key: 'createdAt',
          label: 'Date Added',
          sortable: true,
        },
        {
          key: 'totalLikes',
          label: 'likes',
          sortable: true,
        },
        {
          key: 'commentCount',
          label: 'comments',
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ];
    },
    fieldsWidget() {
      return [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'expectedTypo',
          label: 'Kind',
          sortable: true,
        },
        {
          key: 'size',
          label: 'size',
          sortable: true,
        },
        {
          key: 'createdAt',
          label: 'Date Added',
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ];
    },
    itemsData() {
      return this.$store.getters.storage;
    },
    items() {
      const itemsToIterate = this.searchInput
        ? this.results
        : this.itemsData.unpaginated;
      return itemsToIterate;
    },
    total() {
      return this.$store.getters.storage.meta.total;
    },
    storagePlaceholder() {
      return StoragePlaceholder;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    community() {
      return this.$store.getters.currentCollective;
    },
    canList() {
      return checkPermissions(
        'view',
        'storage',
        this.loggedMemberRoles,
        this.community,
      );
    },
    actualFolderKey() {
      return this.$route.params?.folderKey;
    },
  },
  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    if (this.actualFolderKey) {
      this.folderKey = this.actualFolderKey;
    }
    await this.fetchData(true);
    this.isLoading = false;
  },
  methods: {
    async handleEdit() {
      const name = this.storageInput.isFile
        ? this.storageInput.title
        : Object.values(this.storageInput.title)[0];

      try {
        await this.$store.dispatch('editItem', {
          item: {
            itemType: 'media/name',
            customName: 'storage',
            requestConfig: {
              key: this.storageInput.key,
              name,
            },
          },
        });

        if (!this.storageInput.isFile) {
          await this.fetchData(true);
        }

        this.notifySuccess(this.$t('media.form.success-storage-<message'));
        this.$bvModal.hide('modal-edit-storage');
      } catch {
        this.notifyError(this.$t('error-message.general-error'));
        this.$bvModal.hide('modal-edit-storage');
      }
    },
    showUnshare(item) {
      this.tempItem.key = item.key;
      this.tempItem.isFolder = item.mediaType === 'folder';
      this.isUnshareModalVisible = true;
    },
    showEdit(item) {
      this.storageInput.key = item.key;
      if (typeof item.name !== 'string') {
        this.storageInput.title = item.name;
        this.storageInput.isFile = true;
      } else {
        this.storageInput.title = {};
        this.storageInput.title[this.selectedLanguage] = item.name;
        this.storageInput.isFile = false;
      }
      this.$bvModal.show('modal-edit-storage');
    },
    async deleteItem(key, isFolder) {
      try {
        await this.$store.dispatch('deleteMedia', {
          item: {
            customName: 'storage',
            itemType: 'media',
            requestConfig: {
              mediaKey: key,
            },
          },
        });
      } catch {}
    },
    translate(field) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        field,
      );
    },
    title(name) {
      const translateName = this.translate(name);
      return translateName.replaceAll('_', ' ');
    },
    getImageSrc(url) {
      return getImageResource(url);
    },
    placeholderItems(mediaType) {
      return PlaceholdersImage(mediaType);
    },
    timestampToTime(timestamp) {
      moment.locale(this.currentLocale);
      return moment(timestamp).format('DD MMM YYYY - HH:mm');
    },
    getFileExtensión(fileUrl) {
      if (!fileUrl) {
        return 'Folder';
      }
      const removeQuery = fileUrl.split('?')[0];
      const extension = removeQuery.split('.');
      return extension[extension.length - 1];
    },
    async fetchData(force) {
      await this.$store.dispatch('getItems', {
        itemType: 'storage',
        page: this.lastLoadedPage,
        perPage: 30,
        forceAPICall: force,
        requestConfig: {
          orderByDate: -1,
          morphTypes: ['file', 'folder'],
          folderKey: this.folderKey ? this.folderKey : null,
          classifiers: this.finalSelectClassifier,
          customFields:
            this.customFieldToSend != null
            && Object.keys(this.customFieldToSend).length > 0
              ? this.customFieldToSend
              : null,
        },
      });
    },
    openFolder(folder) {
      this.folders.push(folder);
      if (!this.isWidget) {
        window.history.pushState(
          { folderKey: folder.key },
          '',
          `/${this.community.slug}/storage/${folder.key}`,
        );
      }
      this.$emit('updateFolders', this.folders);
      this.folderKey = folder.key;
      this.fetchData(true);
    },
    goBack() {
      this.folders.pop();
      this.folderKey = this.folders.length > 0
        ? this.folders[this.folders.length - 1].key
        : null;
      if (!this.isWidget) {
        window.history.pushState(
          { folderKey: this.folderKey },
          '',
          `/${this.community.slug}/storage/${this.folderKey || ''}`,
        );
      }
      this.$emit('updateFolders', this.folders);
      this.fetchData(true);
    },
    async handleLoadOfNewItems() {
      if (!this.isLoading && this.items.length < this.total) {
        this.lastLoadedPage += 1;
        this.isLoadingNextPage = true;
        await this.fetchData(false);
        this.isLoadingNextPage = false;
      } else {
        this.isLoadingNextPage = false;
      }
    },
  },
};
</script>

<style></style>
